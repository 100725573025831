import React from "react"
import styled, { css } from "styled-components";

const textSizes = {
  h1: "3rem",
  h2: "2rem",
  h3: "1.2rem"
}

const Wrapper = styled.div`
  display: block;
`;

const TitleElement = styled.span<{ size: string }>(
  ({ size }) => css`
  color: #C64561;
  padding: 2px 2px 0 2px;
  line-height: 1.5;
  font-size: ${size};
`
)

type Props = {
  children: React.ReactNode;
  size: "h1" | "h2" | "h3";
}

const Title = ({ children, size }: Props) => (
  <Wrapper>
    <TitleElement size={textSizes[size]}>
      {children}
    </TitleElement>
  </Wrapper>
)

export default Title
