import React from "react";
import Layout from "../components/layout";
import Title from "../components/elements/title";
import SEO from "../components/seo";
import styled, { css } from "styled-components";
import Link from "../components/elements/link";
import CustomLink from "../components/elements/link";
import ruda from "../images/ruda.png";
import bystrouska from "../images/bystrouska.png";
import bt from "../images/bt_mockup.png";
import design from "../images/bt_design.png";
import screens from "../images/screens.png";
import techStack from "../images/bt_techstack.png";
import Emoji from "../components/elements/emoji";

const FlexRow = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 650px) {
      flex-direction: row;
    }
  `
);

const PersonaWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 650px) {
      &:first-child {
        margin-right: 1.25rem;
      }

      &:last-child {
        margin-left: 1.25rem;
      }
    }
  `
);

const BlogPage = () => {
  return (
    <Layout>
      <SEO title="Beskydské túry" />
      <Title size="h2">Beskydské túry</Title>
      <h3>Obsah</h3>
      <ol>
        <li>
          <Link href="#obt">Co jsou Beskydské túry</Link>
        </li>
        <li>
          <Link href="#persony">Persony</Link>
        </li>
        <li>
          <Link href="#design">Design</Link>
        </li>
        <li>
          <Link href="#implementace">Technické zpracování</Link>
        </li>
        <li>
          <Link href="#ideas">Nápady pro další iterace</Link>
        </li>
        <li>
          <Link href="#conclusion">Shrnutí</Link>
        </li>
      </ol>
      <h3>
        <a name="obt">Co jsou Beskydské túry</a>
      </h3>
      <p>
        Beskydské túry je webová aplikace, jejíž princip je poměrně jednoduchý
        (ikdyž realizace až tak jednodochá nebyla :)) - přehled vybraných tipů
        pro turistické trasy a výlety v Beskydech. Každá trasa obsahuje základní
        informace jako je délka trasy, stoupání, dostupnost trasy (auto, bus,
        vlak), typ trasy (jestli se turista vrací na výchozí místo pro případ,
        že jede autem nebo využívá veřejnou dopravu a nepotřebuje řešit,zda se
        potřebuje vrátit na stejné místo). Každá trasa se zobrazuje v mapě od{" "}
        <CustomLink href="https://mapy.cz" targetBlank>
          Mapy.cz
        </CustomLink>{" "}
        a uživatel si tak pomocí odkazu může tuto trasu rozkliknout v novém okně
        přímo v této aplikaci.
      </p>
      <img src={bt} alt="Beskydské túry" />
      <p>
        Za vytvořením aplikace stálo zejména to, že jsem si chtěla vytvořit svůj
        ,,side project”, který bude komplexnější, co se týče použitých
        technologií, a na kterém se zároveň naučím i něco nového. Delší dobu
        jsem přemýšlela o čem by, aplikace měla být, nechtěla jsem aby to byla
        nějaká hloupost, která skončí v šuplíku a nakonec jsem dostala tento
        nápad.
      </p>
      <h3>
        <a name="persony">Persony</a>
      </h3>
      <p>
        Ve fázi příprav jsem si vytvořila dvě imaginární persony, které mají
        potenciálně zastupovat nejširší skupiny návštěvníků webu.
      </p>
      <FlexRow>
        <PersonaWrapper>
          <img src={ruda} alt="Ruda Jelínek" />
          <h4>Ruda Jelínek</h4>
          <p>
            Ruda je mladý zkušený turista, který v Beskydech tráví skoro každý
            víkend a preferuje delší a náročnější trasy. Nebaví ho turisticky
            vytížená místa a na výlety jezdí veřejnou dopravou. Rád doma kuchtí
            a většinou si na výlet připraví něco dobrého (nejčastěji pečené
            kaštany).
          </p>
        </PersonaWrapper>
        <PersonaWrapper>
          <img src={bystrouska} alt="Liška Bystrouška" />
          <h4>Liška Bystrouška</h4>
          <p>
            Liška Bystrouška pracuje jako IT security manažerka a veškerý svůj
            pracovní čas tráví za počítačem, na výlety chodí jen občas. Protože
            nemá fyzičku jako Ruda, dělají jí radost spíše kratší trasy, kdy se
            může po cestě i zastavit v nějaké horské chatě na dobré jídlo.
            Bystrouška vlastní auto a veřejnou dopravou necestuje.
          </p>
        </PersonaWrapper>
      </FlexRow>
      <h3>
        <a name="design">Design</a>
      </h3>
      <img src={design} alt="Beskydské túry - design" />
      <p>
        Když jsem přemýšlela o designu celé aplikace, měla jsem zhruba jasno v
        základním rozdělení layoutu - navigace, záhlaví, obsah, zápatí. Co se
        týče volby kategorií, nebylo moc o čem přemýšlet - homepage, stránka se
        seznamem tras + detail trasy a stránka s informacemi o webu. Navrhla
        jsem si tedy jednoduchý wireframe na papír, a následně i návrh v Adobe
        XD, který se však ve finále oproti původnímu návrhu designově dost liší.
        Za běhu vývoje jsem měnila design buttonů, dlaždic pro seznam tras,
        apod.
      </p>
      <img src={screens} alt="Beskydské túry - stránky" />
      <p>
        Jako hlavní fotku v zápatí jsem zvolila fotku, kterou jsem pořídila při
        výletu na Lysou horu na podzim 2021. V detailu jednotlivých tras je pak
        v záhlaví odpovídající fotka pro danou trasu.
      </p>
      <h3>
        <a name="implementace">Technické zpracování</a>
      </h3>
      <img src={techStack} alt="Tech stack" />
      <h4>Frontend</h4>
      <p>
        Frontendová část aplikace je postavena na{" "}
        <CustomLink href="https://reactjs.org" targetBlank>
          ReactJS
        </CustomLink>{" "}
        +{" "}
        <CustomLink href="https://www.typescriptlang.org/" targetBlank>
          TypeScriptu
        </CustomLink>{" "}
        a mém oblíbeném frameworku{" "}
        <CustomLink href="https://www.gatsbyjs.com/" targetBlank>
          GatsbyJS
        </CustomLink>
        . Na tomto frameworku se mi líbí široká podpora balíčků a funkcí, pomocí
        nichž je celá realizace jednodušší. Zároveň GatsbyJS podporuje
        <CustomLink href="https://graphql.org/" targetBlank>
          GraphQL
        </CustomLink>
        , který jsem využila pro načítání dat z CMS{" "}
        <CustomLink href="https://strapi.io/" targetBlank>
          Strapi
        </CustomLink>{" "}
        a pro načítání fotek z Instagramu.
      </p>
      <h5>Použité knihovny</h5>
      <p>
        Aplikace stojí zejména na knihovnách{" "}
        <CustomLink href="https://styled-components.com/" targetBlank>
          styled-components
        </CustomLink>
        ,{" "}
        <CustomLink href="https://fontawesome.com/" targetBlank>
          Font Awesome
        </CustomLink>{" "}
        pro ikonky nebo{" "}
        <CustomLink href="https://github.com/flsy/react-mapycz" targetBlank>
          react-mapy
        </CustomLink>
        , která přes API poskytuje podklady Mapy.cz a je pro tento projekt
        stěžejní. S API Mapy.cz se pracuje skrze JavaScript a jelikož Beskydské
        túry jsou postavené na Reactu, hledala jsem knihovnu, která do Reactu
        balí původní Mapy.cz API.
      </p>
      <p>
        Během
        implementace knihovny react-mapycz do svého projektu jsem narazila na podstatný problém a to ten, že v této
        knihovně nebyly obsaženy funkce pro dynamické plánovaní tras, které jsou
        pro Beskydské túry nezbytné. Z tohoto důvodu jsem do této knihovny
        přispěla dvěma pull requesty, prvním pro vykreslení dynamických tras,
        druhým pro získání dat o naplánované trase - potřebovala jsem totiž
        informace i délce trasy, převýšení, apod.
        <br />
        <br />
        <ul>
          <li>
            <CustomLink
              href="https://github.com/flsy/react-mapycz/pull/88"
              targetBlank
            >
              Pull Request #88
            </CustomLink>
          </li>
          <li>
            <CustomLink
              href="https://github.com/flsy/react-mapycz/pull/289"
              targetBlank
            >
              Pull Request #289
            </CustomLink>
          </li>
        </ul>
      </p>
      <h5>Unit testy</h5>
      <p>
        Chtěla jsem si rozšířit znalosti v psaní unit testů a proto jsem do
        projektu přidala několik testů pro otestování komponent a funkcí. Unit
        testy jsem napsala v{" "}
        <CustomLink href="https://jestjs.io/" targetBlank>
          Jestu
        </CustomLink>
        . V první fázi projektu testy zdaleka nepokrývají vše, toto bych ráda v
        budoucnu zlepšila.
      </p>
      <h4>Backend</h4>
      <p>
        Existuje celá řada CMS, které by šlo pro tento projekt použít. Já
        nakonec zvolila CMS{" "}
        <CustomLink href="https://strapi.io/" targetBlank>
          Strapi
        </CustomLink>
        . Líbí se mi několik jeho vlastností - je open-source, relativně snadný
        na implementaci a v dokumentaci je skvělý návod jak propojit Strapi s
        GatsbyJS. Pro databázi využívám Postgres. Protože Strapi neumí v
        kombinaci s Heroku dlouhodobě uchovávat média, propojila jsem podle{" "}
        <CustomLink
          href="https://strapi.io/blog/add-cloudinary-support-to-your-strapi-application"
          targetBlank
        >
          tutoriálu
        </CustomLink>{" "}
        CMS s CDN službou{" "}
        <CustomLink href="https://cloudinary.com/" targetBlank>
          Cloudinary
        </CustomLink>
        .
      </p>
      <h4>Hosting</h4>
      <p>
        Backend aplikace je deploynutý a běží na{" "}
        <CustomLink href="https://heroku.com" targetBlank>
          Heroku
        </CustomLink>
        . Pro frontend jsem zvolila platformu{" "}
        <CustomLink href="https://netlify.com" targetBlank>
          Netlify
        </CustomLink>
        .
      </p>
      <h4>Testování</h4>
      <p>
        V rámci projektu jsem implementovala i několik E2E testů pomocí nástroje{" "}
        <CustomLink href="https://www.cypress.io/" targetBlank>
          Cypress
        </CustomLink>
        . Stejně jako u unit testů, tyto testy zdaleka nepokrývají 100% celou
        aplikaci, ale plánuji je rozšířit.
      </p>
      <h3>
        <a name="ideas">Nápady pro další iterace</a>
      </h3>
      <p>
        Na projektu je stále spousta věcí, které chci vylepšit. Původní plán
        bylo udělat vše najednou a pak postupně přidávat a ladit drobnosti, ale
        kontinuálně dostávám nové a nové nápady, proto nakonec projekt dělím do
        částí, které nazývám iterace. První iterace je hotová, v dalších
        iteracích se chci zaměřit hlavně na:
      </p>
      <ul>
        <li>
          Přidání dalších atributů k trase - dostupnost turistických chat,
          jestli je trasa vhodná na pozorování východu/západu slunce, a podobně.
        </li>
        <li>
          Filtrování a výběr tras podle atributů - vzdálenost, náročnost,
          dostupnost (auto, bus, vlak), typ trasy (jestli se turista vrátí na
          stejné místo, odkud vycházel nebo ne).
        </li>
        <li>Rozšířit unit testy v Jestu a E2E testy v Cypressu.</li>
        <li>Přidat “lightbox” galerii pro detail trasy.</li>
        <li>
          Vyladit responzivní verzi. Responzivní verze funguje, ale chtěla bych
          ji ještě více vylepšit.
        </li>
        <li>Velký code refactoring. Věřím, že kód lze lépe optimalizovat.</li>
        <li>Pokročilé nastavení CI/CD.</li>
        <li>
          Nasazení a konfigurace{" "}
          <CustomLink href="https://eslint.org/" targetBlank>
            ESLint
          </CustomLink>{" "}
          a{" "}
          <CustomLink href="https://www.npmjs.com/package/husky" targetBlank>
            Husky
          </CustomLink>
          .
        </li>
        <li>
          Odladit web z hlediska rychlosti načítání, neb výsledky z Google
          PageSpeed Insights nejsou ideální.
        </li>
        <li>
          A hlavně - získat feedback uživatelů a na jeho základě zlepšit UX/UI
          aplikace.
        </li>
      </ul>
      <h3>
        <a name="conclusion">Shrnutí</a>
      </h3>
      <p>
        Práce na tomto projektů mě baví. S některými nástroji jako je GatsbyJS,
        styled-components, Cypress, apod. mám zkušenosti již z dřívějška, s
        jinými nástroji jsem se v minulosti setkala jen okrajově (např. Heroku
        nebo Strapi) a díky Beskydským túrám jsem si je mohla ,,ohmatat" více.
        Během realizace jsem se klasicky častokrát zasekla na několika
        problémech, ale i ty se mi povedly relativně dobře vyřešit, někdy to
        trvalo sice déle, ale povedlo se. <Emoji label="haha" symbol="😀" />
      </p>
      <p>
        Úspěšně se mi tedy podařilo postavit webovou aplikaci, která je funkční,
        frontend postavený na GatsbyJS komunikuje přes API s backendem, na
        kterém běží CMS a databáze.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: '1rem',
          marginBottom: "2rem",
        }}
      >
        <CustomLink href="https://beskydsketury.cz" targetBlank navLink>
          Web Beskydské túry
        </CustomLink>{" "}
        <CustomLink
          href="https://github.com/sarkaaa/beskydske-tury"
          targetBlank
          navLink
        >
          Kód na Githubu
        </CustomLink>
      </div>
    </Layout>
  );
};

export default BlogPage;
