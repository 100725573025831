import React from "react"
import styled from "styled-components";

const LinkElement = styled.a`
  border-bottom: 1px solid #B3DEC1;
  box-shadow: inset 0 -3px 0 #B3DEC1;
  color: #000;
  transition: all .25s ease-in-out;
  padding: 2px 2px 0 2px;
  font-family: ${({ navLink }) => navLink && "Montserrat"};
  text-transform: ${({ navLink }) => navLink ? "uppercase" : "none"};
  font-weight: ${({ navLink }) => navLink && 400};
  font-size: 1.2rem;
  text-decoration: none;
  word-break: break-word;
  &:hover {
    background-color: #B3DEC1;
  }

  @media screen and (min-width: 450px) {
    font-size: ${({ navLink }) => navLink ? "0.9rem" : "1rem"};
  }
`

type Props = {
  children: React.ReactNode;
  href: string;
  targetBlank?: boolean;
  navLink?: boolean;
  alt?: string;
}

const CustomLink = ({ children, href, targetBlank = false, alt = "", navLink = false }: Props) => (
    <LinkElement href={href} target={targetBlank && "_blank"} alt={alt} navLink={navLink} >
      {children}
    </LinkElement>
)

export default CustomLink
